






































































































import { InformationCircleIcon, ClockIcon } from '@vue-hero-icons/outline';
import Vue from 'vue';
import services from '@/services/server';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import StoreRayonConfig from '@/entities/StoreRayonConfig';

export type Days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

dayjs.extend(isSameOrBefore);
export default Vue.extend({
  name: 'UserCommonPage',
  components: {
    ClockIcon,
    InformationCircleIcon,
  },
  data():
    {
      isLoading: boolean;
      shop: string | null;
      rayonConfig: StoreRayonConfig[],
      familySearch: string
      productNameSearch: string,
      currentLineHovered: number,
      days: Days,
      changeTimeoutId: number | null,
      toastId: number | string | null,
      } {
    return {
      changeTimeoutId: null,
      toastId: null,
      isLoading: false,
      shop: null,
      rayonConfig: [],
      familySearch: '',
      productNameSearch: '',
      currentLineHovered: 0,
      days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    };
  },
  computed: {
    isPreparateurPage(): boolean {
      return this.$route.name === 'OpeningConfigurationPreparateur';
    },
    isBoulangerPage(): boolean {
      return this.$route.name === 'OpeningConfigurationBoulanger';
    },
    productSource(): 'preparateur' | 'boulanger' {
      return this.isPreparateurPage ? 'preparateur' : 'boulanger';
    },
    shopId(): string {
      return this.$store.getters['shopAuth/authenticatedShopId'];
    },
    filteredData(): StoreRayonConfig[] {
      return this.rayonConfig.filter((config) => {
        const family = config.product.family.toLowerCase();
        const productName = config.product.name.toLowerCase();
        const searchFamily = this.familySearch.toLowerCase().trim();
        const searchProductName = this.productNameSearch.toLowerCase().trim();
        if (!searchFamily && !searchProductName) return true;

        return family.includes(searchFamily) && productName.includes(searchProductName);
      });
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    handleLineHover(lineNumber) {
      this.$emit('line-hover', lineNumber);
    },
    isCurrentLineIndex(lineNumber) {
      return lineNumber === this.currentLineHovered;
    },
    async fetchData() {
      this.isLoading = true;
      this.rayonConfig = await services.storeRayonConfigService.getOpeningRayonConfig(
        this.shopId,
        this.productSource,
      );
      this.isLoading = false;
    },
    productName(product) {
      if (product.family !== 'Plaques' && product.quantityPerPlate > 1) {
        return `${product.name} <span class="font-bold">x${product.quantityPerPlate}</span>`;
      }
      return product.name;
    },
    productNameTitle(product) {
      if (product.family !== 'Plaques' && product.quantityPerPlate > 1) {
        return `${product.productName} x${product.quantityPerPlate}`;
      }
      return product.productName;
    },
    placeholder(product) {
      if (product.family === 'Plaques') {
        return 'parts';
      }
      if (product.quantityPerPlate > 1) {
        return 'plaques';
      }
      return 'quantité';
    },
    async update() {
      await services.storeRayonConfigService.updateOpeningRayonConfig(this.shopId, this.rayonConfig);
    },
    async triggerDebouncedChange() {
      if (this.changeTimeoutId) {
        clearTimeout(this.changeTimeoutId);
      }
      if (!this.toastId && this.toastId !== 0) {
        this.toastId = this.$toast.info('Sauvegarde en cours...');
      }
      this.changeTimeoutId = setTimeout(async () => {
        try {
          await this.update();
        } catch (error: any) {
          console.log('Error', error);
        }
        if (this.toastId !== null) {
          this.$toast.dismiss(this.toastId);
        }
        this.toastId = null;
      }, 2000);
    },
  },
});
